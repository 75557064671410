import React from 'react';

function B1EnglishIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      aria-hidden='true'
      viewBox='0 0 175 170'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M138 9H83V79.476H147V18C147 13.029 142.971 9 138 9ZM147 88.476H83V156H138C142.971 156 147 151.971 147 147V88.476ZM74 79.476V9H18C13.029 9 9 13.029 9 18V79.476H72H74ZM9 88.476H74V156H18C13.029 156 9 151.971 9 147V88.476ZM18 0C8.059 0 0 8.059 0 18V147C0 156.941 8.059 165 18 165H138C147.941 165 156 156.941 156 147V18C156 8.059 147.941 0 138 0H18ZM27.54 64.394H36.151V48H42.8115C45.3948 48 47.489 50.0942 47.489 52.6775V52.6775C47.489 55.2608 45.3948 57.355 42.8115 57.355H36.151V64.394H45.63C50.9366 64.394 55.377 60.3669 55.8938 55.0854V55.0854C55.9643 54.3647 55.9435 53.638 55.8317 52.9225L55.5571 51.1642C55.24 49.1331 54.153 47.302 52.5219 46.0509L50.5 44.5L52.0247 43.495C53.9086 42.2532 55.1806 40.2735 55.527 38.0439L55.8246 36.1287C55.9411 35.379 55.9657 34.6179 55.8979 33.8623V33.8623C55.3799 28.0899 50.5423 23.667 44.7467 23.667H27.54V64.394ZM42.04 41.545C45.0494 41.545 47.489 39.1054 47.489 36.096V36.096C47.489 33.0866 45.0494 30.647 42.04 30.647H36.151V41.545H42.04ZM100.301 64.394V57.355H111V45.5C111 44 111 39 111 37.5C111 38.661 111 36.2586 111 37.5C111 38.574 111 36.339 111 37.5C111 38.693 111 36.307 111 37.5C111 35.5 111 35.569 111 35C112.194 35 109.5 35 111 35C111 36 111 34.069 111 35C109.5 36 105 39 104 39.5C103.5 38.5 101 34.5 100.5 33.5C99.2921 35.4577 102 31.069 100.5 33.5C102 32.5 111.5 25.167 114 23.667C118.5 23.667 119 23.667 120.5 23.667C120.5 24.7787 120.5 22.5 120.5 25C120.5 28 120.5 27.931 120.5 29.931C120.5 32.431 120.5 33.154 120.5 35.5C120.5 37.038 120.5 39 120.5 40.5C120.5 42.285 120.5 44 120.5 46C120.5 48.5 120.5 55 120.5 57.355H129.335V64.394H100.301ZM27.54 142V101.273H54.983V108.372H36.151V118.077H53.571V125.176H36.151V134.901H55.063V142H27.54ZM131.605 142V101.273H123.034V126.886H122.676L105.097 101.273H97.54V142H106.151V116.366H106.449L124.168 142H131.605Z'
      />
    </svg>
  );
}

B1EnglishIcon.displayName = 'B1EnglishIcon';

export default B1EnglishIcon;
